import {
    MDBAccordion,
    MDBAccordionItem,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
import { useMemo } from "react";
import {
    paragraphSocialProofChiaraGigliotti,
    paragraphSocialProofFrancescaMinato,
    paragraphSocialProofGuidoBarocchi,
    paragraphSocialProofMarcoFogliamanzillo,
    titleSocialProofChiaraGigliotti,
    titleSocialProofFrancescaMinato,
    titleSocialProofGuidoBarocchi,
    titleSocialProofMarcoFogliamanzillo,
} from "@/utils/mockData/playWithUsMock";
import { useIsMD, useIsSM } from "@/utils/hooks/useWindowSizes";
import Image from "next/image";
import styles from "../../styles/home/Home.SocialProof.module.scss";
import SectionTitle from "../common/SectionTitle";
import { socialTitle, socialSubTitle } from "../../utils/mockData/homeMocks";
import {
    ICardSocialProof,
    ISectionTitle,
    SectionsTitleText,
} from "../../models/common/common";
import Carousel from "../common/carousel/Carousel";

const SocialProof = () => {
    const title: ISectionTitle = {
        title: socialTitle,
        subTitle: socialSubTitle,
        classNameTitle: styles.textTitle,
        classNameSubTitle: styles.textSubTitle,
        titleColumnSize: 12,
        spaceBetween: 4,
        alignment: "justify-content-center text-center",
        classNameContainer: "py-3",
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cardsContent: ICardSocialProof[] = [
        {
            title: titleSocialProofMarcoFogliamanzillo,
            image: "/images/social-proof/marco_fogliamanzillo.webp",
            paragraph: paragraphSocialProofMarcoFogliamanzillo,
        },
        {
            title: titleSocialProofChiaraGigliotti,
            image: "/images/social-proof/chiara_gigliotti.webp",
            paragraph: paragraphSocialProofChiaraGigliotti,
        },
        {
            title: titleSocialProofFrancescaMinato,
            image: "/images/social-proof/francesca_miniato.webp",
            paragraph: paragraphSocialProofFrancescaMinato,
        },
        {
            title: titleSocialProofGuidoBarocchi,
            image: "/images/social-proof/guido_barocchi.webp",
            paragraph: paragraphSocialProofGuidoBarocchi,
        },
    ];

    const formatText = (sectionText: SectionsTitleText[]) => {
        return (
            <>
                {sectionText.map((el, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={`${el.text}-${idx}`}>
                        <span className={`${el.color} ${el.fontWeight}`}>
                            {el.text}
                        </span>{" "}
                        {el.newLine && <br />}
                    </span>
                ))}
            </>
        );
    };

    const isSM = useIsSM();
    const isMD = useIsMD();

    const cards = useMemo(() => {
        return cardsContent.map(item => {
            return (
                <MDBCol size={isMD ? 12 : 10} center>
                    <MDBCard
                        style={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        }}
                    >
                        <MDBCardImage
                            src={item.image}
                            position="top"
                            alt="..."
                            style={{ borderRadius: "20px" }}
                        />
                        <MDBCardBody
                            style={{ width: "100%", padding: "1rem 0" }}
                        >
                            <MDBCardTitle
                                style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                    marginBottom: "1.5rem",
                                }}
                            >
                                {formatText(item.title)}
                            </MDBCardTitle>
                            <MDBCardText
                                style={{ textAlign: "left", fontSize: "16px" }}
                            >
                                {formatText(item.paragraph ?? [])}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            );
        });
    }, [cardsContent, isMD]);

    return (
        <MDBContainer fluid className="py-5 px-0">
            <MDBRow className="justify-content-center py-5 m-md-5">
                <MDBCol center size={10} className={`${styles.containerCard}`}>
                    <SectionTitle sectionTitle={title} />
                </MDBCol>
            </MDBRow>
            <MDBRow
                className={`justify-content-center py-5 ${isSM ? "" : "px-5"} `}
            >
                <MDBCol center size={isSM || isMD ? 12 : 10}>
                    <Carousel
                        centerInsufficientSlides={false}
                        classNameCarousel="w-100 h-100"
                        classNameSlide="d-flex justify-content-center w-fit-content"
                        centerSlides={!!isSM}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.5,
                                spaceBetween: 40,
                                slidesOffsetBefore: 0,
                                slidesOffsetAfter: 0,
                                loop: true,
                            },
                            576: {
                                slidesPerView: 1.3,
                                spaceBetween: 3,
                                slidesOffsetBefore: 0,
                                slidesOffsetAfter: 0,
                                loop: true,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 60,
                                slidesOffsetBefore: 0,
                                slidesOffsetAfter: 0,
                                loop: true,
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                                slidesOffsetBefore: 0,
                                slidesOffsetAfter: 0,
                            },
                        }}
                    >
                        {cards}
                    </Carousel>
                </MDBCol>
            </MDBRow>

            <MDBRow className="justify-content-center m-md-5">
                <MDBCol size={isSM || isMD ? 12 : 8}>
                    <p className={`${styles.bottomCardContentTitle} px-3`}>
                        These four companies joined their forces to create the
                        legal entity{" "}
                        <span className={styles.boldTitle}>
                            Italian Local Expert Srl
                        </span>
                    </p>
                </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-center my-3">
                <MDBCol size={isSM ? 12 : 10}>
                    <p className={styles.bottomCardContentTitleSmall}>
                        This project was funded by
                    </p>
                    <MDBRow className="d-flex justify-content-center ">
                        <MDBCol
                            center
                            size={isSM ? 12 : 8}
                            className={
                                isSM
                                    ? "d-flex justify-content-center p-4"
                                    : "d-flex justify-content-center py-4"
                            }
                        >
                            <div
                                style={{
                                    width: "100%",
                                    margin: "0 auto",
                                    minHeight: "10px",
                                    position: "relative",
                                    aspectRatio: "4/1",
                                }}
                            >
                                <Image
                                    src="/images/social-proof/Logo-firma-PON-ai-fini-pubblicitari.webp"
                                    alt="This project was funded by"
                                    fill
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-center">
                        <MDBCol
                            size={isSM || isMD ? 12 : 10}
                            className={isSM ? "p-4" : ""}
                        >
                            <hr
                                className="hr"
                                style={{ backgroundColor: "#E6E8EC" }}
                            />
                            <MDBAccordion alwaysOpen>
                                <MDBAccordionItem
                                    collapseId={1}
                                    className={styles.accordionItem}
                                    headerTitle={
                                        <p className={styles.accordionTitle}>
                                            More info about the investment
                                            program
                                        </p>
                                    }
                                >
                                    <p className={styles.accordionText}>
                                        The investment program focused on the
                                        development of an e-commerce platform
                                        that offers exclusive services to
                                        international travelers. Additionally,
                                        activities related to the Play Italy
                                        branding were planned and implemented.
                                    </p>
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default SocialProof;
