import { MDBCol } from "mdb-react-ui-kit";
import { FC, ReactNode } from "react";
import { TBreackpointsCarousel } from "../../../models/common/common";
import Carousel from "../../common/carousel/Carousel";

const FourStepsCarousel: FC<{
    children: ReactNode[];
}> = ({ children }) => {
    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1,
            spaceBetween: 32,
            autoHeight: true,
        },
        360: {
            slidesPerView: 1.2,
            spaceBetween: 32,
            autoHeight: true,
        },
        400: {
            slidesPerView: 1.2,
            spaceBetween: 32,
            autoHeight: true,
        },
        540: {
            slidesPerView: 1.5,
            spaceBetween: 32,
            autoHeight: true,
        },
        576: {
            slidesPerView: 1.6,
            spaceBetween: 20,
            autoHeight: true,
        },
    };

    return (
        <MDBCol center size={12} md={10}>
            <Carousel visibleSlides={3} breakpoints={breackPointCarousel}>
                {children}
            </Carousel>
        </MDBCol>
    );
};

export default FourStepsCarousel;
